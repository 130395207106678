const emojiData = [
    // Smileys & Emotion
    { emoji: '😀', name: 'Grinning Face', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['wide smile', 'showing teeth'] },
    { emoji: '😃', name: 'Grinning Face with Big Eyes', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['big eyes', 'open mouth'] },
    { emoji: '😄', name: 'Grinning Face with Smiling Eyes', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['smiling eyes', 'open mouth'] },
    { emoji: '😁', name: 'Beaming Face with Smiling Eyes', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['grinning', 'squinting eyes'] },
    { emoji: '😆', name: 'Grinning Squinting Face', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['closed eyes', 'open mouth'] },
    { emoji: '😅', name: 'Grinning Face with Sweat', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['smiling', 'sweat drop'] },
    { emoji: '🤣', name: 'Rolling on the Floor Laughing', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['laughing', 'rotated'] },
    { emoji: '😂', name: 'Face with Tears of Joy', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['laughing', 'tears'] },
    { emoji: '🙂', name: 'Slightly Smiling Face', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['small smile', 'neutral eyes'] },
    { emoji: '🙃', name: 'Upside-Down Face', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['inverted', 'smiling'] },
    { emoji: '😉', name: 'Winking Face', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['winking eye', 'slight smile'] },
    { emoji: '😊', name: 'Smiling Face with Smiling Eyes', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['blushing', 'smiling eyes'] },
    { emoji: '😇', name: 'Smiling Face with Halo', category: 'Smileys & Emotion', subcategory: 'face-smiling', sentiment: 'positive', features: ['halo', 'angelic'] },
    { emoji: '🥰', name: 'Smiling Face with Hearts', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['hearts', 'loving'] },
    { emoji: '😍', name: 'Smiling Face with Heart-Eyes', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['heart eyes', 'loving'] },
    { emoji: '🤩', name: 'Star-Struck', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['star eyes', 'excited'] },
    { emoji: '😘', name: 'Face Blowing a Kiss', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['kissing', 'heart'] },
    { emoji: '😗', name: 'Kissing Face', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['kissing lips', 'neutral eyes'] },
    { emoji: '😚', name: 'Kissing Face with Closed Eyes', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['kissing', 'closed eyes'] },
    { emoji: '😙', name: 'Kissing Face with Smiling Eyes', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'positive', features: ['kissing', 'smiling eyes'] },
    { emoji: '🥲', name: 'Smiling Face with Tear', category: 'Smileys & Emotion', subcategory: 'face-affection', sentiment: 'mixed', features: ['smiling', 'single tear'] },
  
    // People & Body
    { emoji: '👋', name: 'Waving Hand', category: 'People & Body', subcategory: 'hand-fingers-open', sentiment: 'neutral', features: ['greeting', 'goodbye'] },
    { emoji: '🤚', name: 'Raised Back of Hand', category: 'People & Body', subcategory: 'hand-fingers-open', sentiment: 'neutral', features: ['palm', 'stop'] },
    { emoji: '🖐️', name: 'Hand with Fingers Splayed', category: 'People & Body', subcategory: 'hand-fingers-open', sentiment: 'neutral', features: ['spread fingers', 'palm'] },
    { emoji: '✋', name: 'Raised Hand', category: 'People & Body', subcategory: 'hand-fingers-open', sentiment: 'neutral', features: ['high five', 'stop'] },
    { emoji: '🖖', name: 'Vulcan Salute', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['Spock', 'Star Trek'] },
    { emoji: '👌', name: 'OK Hand', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['OK', 'perfect'] },
    { emoji: '🤌', name: 'Pinched Fingers', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'neutral', features: ['Italian hand gesture', 'what do you want'] },
    { emoji: '🤏', name: 'Pinching Hand', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'neutral', features: ['small amount', 'tiny'] },
    { emoji: '✌️', name: 'Victory Hand', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['peace', 'victory'] },
    { emoji: '🤞', name: 'Crossed Fingers', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['luck', 'hopeful'] },
    { emoji: '🤟', name: 'Love-You Gesture', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['ILY', 'rock on'] },
    { emoji: '🤘', name: 'Sign of the Horns', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['rock on', 'devil horns'] },
    { emoji: '🤙', name: 'Call Me Hand', category: 'People & Body', subcategory: 'hand-fingers-partial', sentiment: 'positive', features: ['phone', 'hang loose'] },
  
    // Animals & Nature
    { emoji: '🐶', name: 'Dog Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['canine', 'pet'] },
    { emoji: '🐱', name: 'Cat Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['feline', 'pet'] },
    { emoji: '🐭', name: 'Mouse Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'neutral', features: ['rodent', 'small'] },
    { emoji: '🐹', name: 'Hamster', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['rodent', 'pet'] },
    { emoji: '🐰', name: 'Rabbit Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['bunny', 'ears'] },
    { emoji: '🦊', name: 'Fox', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['cunning', 'orange'] },
    { emoji: '🐻', name: 'Bear', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'neutral', features: ['large', 'furry'] },
    { emoji: '🐼', name: 'Panda', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['black and white', 'bamboo'] },
    { emoji: '🐨', name: 'Koala', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'positive', features: ['Australian', 'eucalyptus'] },
    { emoji: '🐯', name: 'Tiger Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'neutral', features: ['stripes', 'feline'] },
    { emoji: '🦁', name: 'Lion', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'neutral', features: ['mane', 'big cat'] },
    { emoji: '🐮', name: 'Cow Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'neutral', features: ['farm', 'milk'] },
    { emoji: '🐷', name: 'Pig Face', category: 'Animals & Nature', subcategory: 'animal-mammal', sentiment: 'neutral', features: ['pink', 'snout'] },
  
    // Food & Drink
    { emoji: '🍎', name: 'Red Apple', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'red'] },
    { emoji: '🍐', name: 'Pear', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'green'] },
    { emoji: '🍊', name: 'Tangerine', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'orange'] },
    { emoji: '🍋', name: 'Lemon', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'yellow'] },
    { emoji: '🍌', name: 'Banana', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'yellow'] },
    { emoji: '🍉', name: 'Watermelon', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'positive', features: ['fruit', 'summer'] },
    { emoji: '🍇', name: 'Grapes', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'purple'] },
    { emoji: '🍓', name: 'Strawberry', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'positive', features: ['fruit', 'red'] },
    { emoji: '🫐', name: 'Blueberries', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'blue'] },
    { emoji: '🍒', name: 'Cherries', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'red'] },
    { emoji: '🥝', name: 'Kiwi Fruit', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'green'] },
    { emoji: '🍅', name: 'Tomato', category: 'Food & Drink', subcategory: 'food-vegetable', sentiment: 'neutral', features: ['vegetable', 'red'] },
    { emoji: '🥥', name: 'Coconut', category: 'Food & Drink', subcategory: 'food-fruit', sentiment: 'neutral', features: ['fruit', 'tropical'] },
  
    // Travel & Places
    { emoji: '🚗', name: 'Automobile', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['car', 'vehicle'] },
  { emoji: '🚕', name: 'Taxi', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['cab', 'vehicle'] },
  { emoji: '🚙', name: 'Sport Utility Vehicle', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['SUV', 'car'] },
  { emoji: '🚌', name: 'Bus', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['public transport', 'vehicle'] },
  { emoji: '🚎', name: 'Trolleybus', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['electric', 'public transport'] },
  { emoji: '🏎️', name: 'Racing Car', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['speed', 'sports'] },
  { emoji: '🚓', name: 'Police Car', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['law enforcement', 'emergency'] },
  { emoji: '🚑', name: 'Ambulance', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['emergency', 'medical'] },
  { emoji: '🚒', name: 'Fire Engine', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['firefighter', 'emergency'] },
  { emoji: '🚐', name: 'Minibus', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['van', 'small bus'] },
  { emoji: '🚚', name: 'Delivery Truck', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['shipping', 'freight'] },
  { emoji: '🚛', name: 'Articulated Lorry', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['truck', 'semi'] },
  { emoji: '🚜', name: 'Tractor', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['farm', 'agriculture'] },
  { emoji: '🏍️', name: 'Motorcycle', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['bike', 'two-wheeler'] },
  { emoji: '🛵', name: 'Motor Scooter', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['vespa', 'moped'] },
  { emoji: '🚲', name: 'Bicycle', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'positive', features: ['bike', 'cycling'] },
  { emoji: '🚂', name: 'Locomotive', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['train', 'steam engine'] },
  { emoji: '🚆', name: 'Train', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['rail', 'public transport'] },
  { emoji: '🚊', name: 'Tram', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['streetcar', 'public transport'] },
  { emoji: '🚉', name: 'Station', category: 'Travel & Places', subcategory: 'transport-ground', sentiment: 'neutral', features: ['train station', 'platform'] },

  // Transport Air
  { emoji: '✈️', name: 'Airplane', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['flight', 'aviation'] },
  { emoji: '🛫', name: 'Airplane Departure', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['takeoff', 'leaving'] },
  { emoji: '🛬', name: 'Airplane Arrival', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['landing', 'arriving'] },
  { emoji: '🚁', name: 'Helicopter', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['chopper', 'flying'] },
  { emoji: '🚀', name: 'Rocket', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['spaceship', 'launch'] },
  { emoji: '🛸', name: 'Flying Saucer', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['UFO', 'alien'] },
  { emoji: '🛰️', name: 'Satellite', category: 'Travel & Places', subcategory: 'transport-air', sentiment: 'neutral', features: ['space', 'orbit'] },

  // Transport Water
  { emoji: '🛳️', name: 'Passenger Ship', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'neutral', features: ['cruise', 'ferry'] },
  { emoji: '⛴️', name: 'Ferry', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'neutral', features: ['boat', 'transport'] },
  { emoji: '🚢', name: 'Ship', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'neutral', features: ['boat', 'ocean liner'] },
  { emoji: '⛵', name: 'Sailboat', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'positive', features: ['yacht', 'sailing'] },
  { emoji: '🚤', name: 'Speedboat', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'neutral', features: ['motorboat', 'fast'] },
  { emoji: '🛥️', name: 'Motor Boat', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'neutral', features: ['yacht', 'speedboat'] },
  { emoji: '🚣', name: 'Person Rowing Boat', category: 'Travel & Places', subcategory: 'transport-water', sentiment: 'neutral', features: ['rowing', 'canoe'] },

  // Places
  { emoji: '🏠', name: 'House', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'positive', features: ['home', 'residence'] },
  { emoji: '🏡', name: 'House with Garden', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'positive', features: ['home', 'yard'] },
  { emoji: '🏢', name: 'Office Building', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['work', 'corporate'] },
  { emoji: '🏣', name: 'Japanese Post Office', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['mail', 'Japan'] },
  { emoji: '🏤', name: 'Post Office', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['mail', 'letters'] },
  { emoji: '🏥', name: 'Hospital', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['medical', 'emergency'] },
  { emoji: '🏦', name: 'Bank', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['money', 'finance'] },
  { emoji: '🏨', name: 'Hotel', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['lodging', 'vacation'] },
  { emoji: '🏪', name: 'Convenience Store', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['shop', '24-hours'] },
  { emoji: '🏫', name: 'School', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['education', 'learning'] },
  { emoji: '🏛️', name: 'Classical Building', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['architecture', 'columns'] },
  { emoji: '⛪', name: 'Church', category: 'Travel & Places', subcategory: 'place-religious', sentiment: 'neutral', features: ['Christian', 'religion'] },
  { emoji: '🕌', name: 'Mosque', category: 'Travel & Places', subcategory: 'place-religious', sentiment: 'neutral', features: ['Islam', 'religion'] },
  { emoji: '🕍', name: 'Synagogue', category: 'Travel & Places', subcategory: 'place-religious', sentiment: 'neutral', features: ['Jewish', 'religion'] },
  { emoji: '🕋', name: 'Kaaba', category: 'Travel & Places', subcategory: 'place-religious', sentiment: 'neutral', features: ['Islam', 'Mecca'] },
  { emoji: '⛩️', name: 'Shinto Shrine', category: 'Travel & Places', subcategory: 'place-religious', sentiment: 'neutral', features: ['Japan', 'religion'] },

  // Places Geographic
  { emoji: '🗻', name: 'Mount Fuji', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'neutral', features: ['Japan', 'mountain'] },
  { emoji: '🏔️', name: 'Snow-Capped Mountain', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'neutral', features: ['peak', 'alpine'] },
  { emoji: '⛰️', name: 'Mountain', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'neutral', features: ['hill', 'peak'] },
  { emoji: '🌋', name: 'Volcano', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'neutral', features: ['eruption', 'lava'] },
  { emoji: '🏕️', name: 'Camping', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'positive', features: ['outdoors', 'tent'] },
  { emoji: '🏖️', name: 'Beach with Umbrella', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'positive', features: ['seaside', 'vacation'] },
  { emoji: '🏜️', name: 'Desert', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'neutral', features: ['arid', 'sand'] },
  { emoji: '🏝️', name: 'Desert Island', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'positive', features: ['tropical', 'beach'] },
  { emoji: '🏞️', name: 'National Park', category: 'Travel & Places', subcategory: 'place-geographic', sentiment: 'positive', features: ['nature', 'preservation'] },

  // Famous Landmarks
  { emoji: '🗽', name: 'Statue of Liberty', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'positive', features: ['New York', 'USA'] },
  { emoji: '🗼', name: 'Tokyo Tower', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['Japan', 'landmark'] },
  { emoji: '🎌', name: 'Crossed Flags', category: 'Travel & Places', subcategory: 'flag', sentiment: 'neutral', features: ['Japan', 'ceremony'] },
  { emoji: '🏰', name: 'Castle', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['fairytale', 'fortress'] },
  { emoji: '🏯', name: 'Japanese Castle', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['fortress', 'palace'] },
  { emoji: '🏟️', name: 'Stadium', category: 'Travel & Places', subcategory: 'place-building', sentiment: 'neutral', features: ['sports', 'arena'] },
  { emoji: '🎡', name: 'Ferris Wheel', category: 'Travel & Places', subcategory: 'place-other', sentiment: 'positive', features: ['amusement park', 'carnival'] },
  { emoji: '⛲', name: 'Fountain', category: 'Travel & Places', subcategory: 'place-other', sentiment: 'positive', features: ['water', 'decoration'] },
  { emoji: '⛱️', name: 'Umbrella on Ground', category: 'Travel & Places', subcategory: 'place-other', sentiment: 'positive', features: ['beach', 'shade'] },
  
  // Activities
  { emoji: '🎭', name: 'Performing Arts', category: 'Activities', subcategory: 'event', sentiment: 'neutral', features: ['theater masks', 'drama'] },
  { emoji: '🎨', name: 'Artist Palette', category: 'Activities', subcategory: 'arts & crafts', sentiment: 'neutral', features: ['painting', 'colors'] },
  { emoji: '⚽', name: 'Soccer Ball', category: 'Activities', subcategory: 'sport', sentiment: 'neutral', features: ['football', 'round'] },
  
  // Objects
  { emoji: '💡', name: 'Light Bulb', category: 'Objects', subcategory: 'light & video', sentiment: 'positive', features: ['idea', 'electricity'] },
  { emoji: '🎁', name: 'Wrapped Gift', category: 'Objects', subcategory: 'mail', sentiment: 'positive', features: ['present', 'box with bow'] },
  { emoji: '⏰', name: 'Alarm Clock', category: 'Objects', subcategory: 'time', sentiment: 'neutral', features: ['wake up', 'timepiece'] },
  
  // Symbols
  { emoji: '❤️', name: 'Red Heart', category: 'Symbols', subcategory: 'heart', sentiment: 'positive', features: ['love', 'affection'] },
  { emoji: '🔣', name: 'Input Symbols', category: 'Symbols', subcategory: 'alphanum', sentiment: 'neutral', features: ['characters', 'typing'] },
  { emoji: '♻️', name: 'Recycling Symbol', category: 'Symbols', subcategory: 'other-symbol', sentiment: 'positive', features: ['environment', 'green'] },
  
  // Flags
  { emoji: '🏳️‍🌈', name: 'Rainbow Flag', category: 'Flags', subcategory: 'flag', sentiment: 'positive', features: ['pride', 'LGBTQ+'] },
  { emoji: '🏴‍☠️', name: 'Pirate Flag', category: 'Flags', subcategory: 'flag', sentiment: 'neutral', features: ['skull and crossbones', 'maritime'] },
  { emoji: '🇺🇳', name: 'Flag: United Nations', category: 'Flags', subcategory: 'country-flag', sentiment: 'neutral', features: ['international organization', 'peace'] }
];

export default emojiData;